<template>
  <div>
    <div class="logo-bg">
    </div>
    <div class="logo">
      <img class="logo-img" src="~images/dow-ceremony/download-logo.png" alt="">
    </div>
    <div class="download-text">
      <p class="download-font">大大汽修</p>
      <p class="download-font-tow">下载获取更多积分赢大奖</p>
    </div>
    <div class="download-btn" @click="gotoDownLoadIOS">IOS 版下载</div>
    <div class="download-btn-red" @click="gotoDownLoadAndroid">安卓版下载</div>
    <div class="please-use-web">安卓版请使用浏览器打开下载</div>
  </div>
</template>

<script>
import { downLoadUrl } from 'api/user'
export default {
  data () {
    return {
      androidUrl: ''
    }
  },
  mounted () {
    this.getDownLoadUrl()
  },
  methods: {
    getDownLoadUrl () {
      let params = { source: 0 }
      downLoadUrl(JSON.stringify(params)).then(res => {
        if (res.data.result !== 0) {
          this.$createToast({
            txt: res.data.desc,
            type: 'error',
            time: 2000
          }).show()
          return
        }
        this.androidUrl = res.data.data.url
      })
    },
    gotoDownLoadIOS () {
      window.open('https://itunes.apple.com/cn/app/%E8%BE%BE%E6%AC%A7%E9%97%A8%E5%BA%97/id1335892361?mt=8')
    },
    gotoDownLoadAndroid () {
      window.open(this.androidUrl)
    }
  }
}
</script>

 <style lang="scss" scoped>
@import "~styles/index.scss";
.logo-bg {
  position: fixed;
  z-index: -1;
  top: 0px;
  left: 0px;
  right: 0;
  bottom: 0px;
  background: url(~images/dow-ceremony/download-bg.png) no-repeat;
  background-size: 100%;
  background-size: cover;
}
.logo {
  z-index: 9;
  width: px2rem(150px);
  height: px2rem(280px);
  margin: px2rem(10px) auto;
  // box-shadow: 0px 0px 23px 0px rgba(0, 0, 0, 0.06);
  // display: inline-block;
  display: flex;
  align-items: center;
  .logo-img{
    width: px2rem(150px);
    height: px2rem(150px);
  }
}
.download-text {
  width: 308px;
  z-index: 9;
  font-size: px2rem(48px);
  color: #fff;
  text-align: center;
  line-height: px2rem(40px);
  margin: 0 auto;
  .download-font {
    font-size: px2rem(48px);
    margin: px2rem(5px) 0;
  }
  .download-font-tow {
    font-size: px2rem(28px);
    margin: px2rem(20px) 0;
  }
}
.download-btn {
  width: px2rem(450px);
  height: px2rem(90px);
  background: #4081d6;
  border-radius: px2rem(45px);
  font-size: px2rem(32px);
  line-height: px2rem(90px);
  color: #fff;
  text-align: center;
  margin-top: px2rem(127px);
  margin: px2rem(127px) auto 0 auto;
}
.download-btn-red {
  width: px2rem(450px);
  height: px2rem(90px);
  background: #fc4c5a;
  border-radius: px2rem(45px);
  font-size: px2rem(32px);
  line-height: px2rem(90px);
  color: #fff;
  text-align: center;
  margin: px2rem(60px) auto 0;
}
.please-use-web {
  margin: 0.4rem auto 0;
  text-align: center;
  width: 100%;
  height: px2rem(40px);
  font-size: px2rem(28px);
  font-family: PingFangSC-Regular;
  color: rgba(255, 255, 255, 1);
  line-height: px2rem(40px);
}
</style>
